document.addEventListener('DOMContentLoaded', function () {
  //slider of promo images
  var $slider = $('.templates-promo__card_slider');
  if ($slider.length) {
    $slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false
    });
  }

  //load more button logic
  var listItems = function listItems() {
    return document.querySelectorAll('.templates-list__item_can_visible');
  };
  var loadMoreButton = document.querySelector('.templates-list__button');
  var itemsToShow = getItemsToShow(window.innerWidth);
  var currentVisibleItems = 0;
  function getItemsToShow(width) {
    switch (true) {
      case width < 604 && width > 0:
        return 5;
      case width < 956 && width >= 604:
        return 10;
      case width < 1258 && width >= 956:
        return 15;
      default:
        return 20;
    }
  }
  function showItems() {
    var newVisibleItems = Math.min(currentVisibleItems + itemsToShow, listItems().length);
    listItems().forEach(function (item, index) {
      if (index >= currentVisibleItems && index < newVisibleItems) {
        item.classList.add('templates-list__item_visible');
      }
    });
    currentVisibleItems = newVisibleItems;
    if (currentVisibleItems >= listItems().length && loadMoreButton) {
      loadMoreButton.classList.add('templates-list__button_hidden');
    }
  }

  //showing buttons only when tapping on mobile display
  function showMobileButtons() {
    listItems().forEach(function (card) {
      card.addEventListener('click', function () {
        if (window.innerWidth < 767) {
          listItems().forEach(function (listItem) {
            listItem.classList.remove('templates-list__item_active');
          });
          card.classList.add('templates-list__item_active');
        }
      });
    });
  }
  function init() {
    listItems().forEach(function (item) {
      item.classList.remove('templates-list__item_visible');
    });
    currentVisibleItems = 0;
    showItems();
    showMobileButtons();
    if (currentVisibleItems < listItems().length) {
      loadMoreButton.classList.remove('templates-list__button_hidden');
      loadMoreButton.classList.add('templates-list__button_visible');
    }
  }
  function resize() {
    itemsToShow = getItemsToShow(window.innerWidth);
    init();
  }
  if (loadMoreButton) {
    loadMoreButton.addEventListener('click', showItems);
  }
  window.addEventListener('resize', resize);
  document.addEventListener('updateVisibleItems', resize);
});