import anime from 'animejs/lib/anime.es.js';
document.addEventListener('DOMContentLoaded', function () {
  anime({
    targets: '.templates-promo__img-wrapper',
    easing: 'easeOutQuad',
    translateX: ['80%', '0%'],
    translateY: ['165%', '0%'],
    duration: 700
  });
  var promoDiamonds = document.querySelectorAll('.js-templates-promo-diamond');
  anime({
    targets: promoDiamonds,
    scale: [0, 1],
    rotate: ['45deg', '0deg'],
    delay: function delay(el, i, l) {
      return i * 200;
    },
    easing: 'easeInSine',
    duration: 500
  });
});