import anime from 'animejs/lib/anime.es.js';
document.addEventListener('DOMContentLoaded', function () {
  var showElements = document.querySelectorAll('.show-scrolling');
  var callback = function callback(entries, observer) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        anime({
          targets: '.show-scrolling',
          easing: 'easeOutQuad',
          translateX: ['90%', '0%'],
          opacity: 1,
          duration: 1000
        });
        observer.unobserve(entry.target);
      }
    });
  };
  var observer = new IntersectionObserver(callback);
  showElements.forEach(function (el) {
    observer.observe(el);
  });
});