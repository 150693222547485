// return default

document.addEventListener('DOMContentLoaded', function () {
  var menuLinks = document.querySelectorAll('.header__mobile-menu-link');
  var headerLogo = document.querySelector('.header__logo');
  var menuBtn = document.querySelector('.header__mobile-menu-button');
  var mobileMenu = document.querySelector('.header__mobile-menu');
  var scrollToSection = function scrollToSection(targetName, targetSectionOffsetTop) {
    var targetSection = document.getElementById(targetName);
    if (targetSection) {
      var topOfTargetSection = targetSection.offsetTop - targetSectionOffsetTop;
      window.scrollTo({
        top: topOfTargetSection,
        behavior: 'smooth'
      });
      if (!window.location.pathname === '/') {
        window.scrollTo({
          top: topOfTargetSection,
          behavior: 'smooth'
        });
      }
    }
  };
  menuLinks.forEach(function (link) {
    link.addEventListener('click', function (event) {
      var href = this.getAttribute('href');
      if (href.startsWith('/#')) {
        event.preventDefault();
        headerLogo.classList.remove('active');
        menuBtn.classList.remove('active');
        mobileMenu.classList.remove('active');
        var body = document.body;
        var scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
        window.location.href = href;
        var targetId = this.getAttribute('href').slice(2);
        if (targetId === 'faq') {
          var targetSection = document.getElementById(targetId);
          targetSection.scrollIntoView({
            behavior: 'auto',
            block: 'end'
          });
        } else {
          scrollToSection(targetId, 80);
        }
      }
    });
  });
});