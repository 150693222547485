import './lib/sharer.min';
import './common/animation/main-promo-animation.js';
import './common/animation/menu-button-animation.js';
import './common/animation/promo-img-animation.js';
import './common/animation/promo-templates-animation.js';
import './common/mobile-anchor-link.js';
import './common/animation/show-scrolling';
import './common/fix-header-on-scroll.js';
import './common/update-year.js';
import './common/cookie-popup';
import './common/share-buttons';
import './templates/templates.js';
import './templates/templates-select.js';
import './templates/template-mobile-notification';