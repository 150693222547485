document.addEventListener('DOMContentLoaded', function () {
  var mobileDialog = document.querySelector('.mobile-notification');
  var dialogLinkButton = document.querySelector('.mobile-notification__button_for_constructor');
  var dialogCloseButton = document.querySelector('.mobile-notification__button_for_close');
  var listItems = document.querySelectorAll('.templates-list__item_can_visible');
  var removeListItemsActiveClass = function removeListItemsActiveClass(itemsArray, itemActiveClass) {
    itemsArray.forEach(function (item) {
      if (item.classList.contains(itemActiveClass)) {
        item.classList.remove(itemActiveClass);
      }
    });
  };
  if (dialogCloseButton) {
    dialogCloseButton.addEventListener('click', function () {
      if (listItems) {
        removeListItemsActiveClass(listItems, 'templates-list__item_active');
      }
      mobileDialog.close();
    });
  }
  window.openpop = function (url) {
    dialogLinkButton.href = url;
    mobileDialog.showModal();
  };
});