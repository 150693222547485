import anime from 'animejs/lib/anime.es.js';
document.addEventListener('DOMContentLoaded', function () {
  var mainPromoDiamonds = document.querySelectorAll('.js-promo-diamond');
  anime({
    targets: mainPromoDiamonds,
    scale: [0, 1],
    rotate: '-45deg',
    delay: function delay(el, i, l) {
      return i * 200;
    },
    easing: 'easeInSine',
    duration: 500
  });
});