document.addEventListener('DOMContentLoaded', function () {
  var customSelects = document.querySelectorAll('.templates-list__select');
  var list = document.querySelector('.templates-list__list');
  // const items = list.querySelectorAll('.templates-list__item');

  var all = Array.from(document.querySelectorAll('.templates-list__item')).map(function (i) {
    var categories = new Set(Object.keys(i.dataset));
    var item = i.cloneNode(true);
    return {
      categories: categories,
      item: item
    };
  });
  var filtred = all;
  var categoriesMap = all.map(function (_ref) {
    var categories = _ref.categories;
    return categories;
  });
  var updateList = function updateList() {
    filtred = all;
    document.querySelectorAll('.templates-list__select:not(.active)').forEach(function (customSelect) {
      var selected = new Set(Array.from(customSelect.querySelectorAll('.templates-list__select-input:checked')).map(function (e) {
        return e.name;
      }));
      if (selected.size) {
        filtred = filtred.filter(function (_ref2) {
          var categories = _ref2.categories;
          return selected.intersection(categories).size;
        });
      }
    });
    var selectedActive = new Set(Array.from(document.querySelectorAll('.templates-list__select.active .templates-list__select-input:checked')).map(function (e) {
      return e.name;
    }));
    if (selectedActive.size) {
      filtred = filtred.filter(function (_ref3) {
        var categories = _ref3.categories;
        return selectedActive.intersection(categories).size;
      });
    }
    list.querySelectorAll('.templates-list__item').forEach(function (item) {
      return item.remove();
    });
    filtred.forEach(function (_ref4) {
      var item = _ref4.item;
      return list.appendChild(item.cloneNode(true));
    });
    document.dispatchEvent(new CustomEvent('updateVisibleItems'));
  };
  customSelects.forEach(function (customSelect) {
    var selectBtn = customSelect.querySelector('.templates-list__select-button');
    var selectCheckboxes = customSelect.querySelectorAll('.templates-list__select-input');
    var calcQuantity = function calcQuantity(item) {
      var quantity = item.querySelector('.templates-list__select-quantity');
      var input = item.querySelector('.templates-list__select-input');

      // const others = new Set(Array.from(customSelects).filter((i) => i !== customSelect).map((i) => Array.from(i.querySelectorAll('.templates-list__select-input:checked')).map((e) => e.name)).flat());
      // const listOther = all.filter(({ categories }) => categories.intersection(others).size);
      //
      var lfiltred = all;
      document.querySelectorAll('.templates-list__select').forEach(function (cS) {
        if (cS === customSelect) return;
        var selected = new Set(Array.from(cS.querySelectorAll('.templates-list__select-input:checked')).map(function (e) {
          return e.name;
        }));
        if (selected.size) {
          lfiltred = lfiltred.filter(function (_ref5) {
            var categories = _ref5.categories;
            return selected.intersection(categories).size;
          });
        }
      });

      // console.log('----');
      // console.log(others);
      // console.log(listOther.length);

      var collection = filtred;
      if (lfiltred.length && !input.checked) {
        collection = lfiltred;
      }
      if (!lfiltred.length) {
        collection = all;
      }
      var size = collection.filter(function (_ref6) {
        var categories = _ref6.categories;
        return categories.intersection(new Set([input.name])).size;
      }).length;
      quantity.innerText = size;
      input.disabled = !input.checked && !size;
    };
    var updateSelectItems = function updateSelectItems() {
      if (!customSelect.classList.contains('active')) return;
      customSelect.querySelectorAll('.templates-list__select-item').forEach(calcQuantity);
    };
    var updateButtonClass = function updateButtonClass() {
      var e = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var isChecked = Array.from(selectCheckboxes).some(function (checkbox) {
        return checkbox.checked;
      });
      if (isChecked) {
        selectBtn.classList.add('templates-list__select-button_selected');
      } else {
        selectBtn.classList.remove('templates-list__select-button_selected');
        if (e) {
          // setTimeout(updateSelectItems, 100);
          updateSelectItems();
        }
      }
      updateList();
    };
    selectCheckboxes.forEach(function (checkbox) {
      checkbox.addEventListener('change', updateButtonClass);
    });
    updateButtonClass();
    selectBtn.addEventListener('click', function () {
      customSelects.forEach(function (otherSelect) {
        if (otherSelect !== customSelect) {
          otherSelect.classList.remove('active');
          otherSelect.querySelector('.templates-list__select-button').setAttribute('aria-expanded', 'false');
        }
      });
      customSelect.classList.toggle('active');

      //calc
      updateSelectItems();
      selectBtn.setAttribute('aria-expanded', customSelect.classList.contains('active') ? 'true' : 'false');
    });
  });
  document.addEventListener('click', function (event) {
    customSelects.forEach(function (customSelect) {
      if (!customSelect.contains(event.target)) {
        customSelect.classList.remove('active');
        customSelect.querySelector('.templates-list__select-button').setAttribute('aria-expanded', 'false');
      }
    });
  });
});